/******************************************************************************
 *
 *  (C) 2022 AhnLab, Inc. All rights reserved.
 *  Any part of this source code can not be copied with any method without
 *  prior written permission from the author or authorized person.
 *
 ******************************************************************************/
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
 
//use는 Vue의 플러그인, Vue에 전역으로 사용하는 기능 추가
Vue.use(Vuex);
 
// ES6 문법에 의하여 store 변수는 외부에서 사용 가능
export const store = new Vuex.Store({
    state: {
        confirmToastButton : {
            addProduct : {
                toastMsg : '제품 추가가 완료되었습니다.',
                toastBtn : '추가'
            },
            deleteProduct : {
                toastMsg : '제품이 삭제되었습니다.',
                toastBtn : '확인'
            },
            editProductVersion : {
                toastMsg : '제품 수정이 완료되었습니다.',
                toastBtn : '저장'
            },
            deleteProductVersion : {
                toastMsg : '제품의 버전 정보가 삭제되었습니다.',
                toastBtn : '확인'
            },
            recoverProduct : {
                toastMsg : '제품이 복구가 완료되었습니다.',
                toastBtn : '확인'
            },
            changePassword : {
                toastMsg : '패스워드 변경이 완료되었습니다.',
                toastBtn : '적용'
            }
        },
        addProductModal : {
            btnValid : true,
        },
        token : null,
        confirmModal : {
            deleteProduct : '제품을 삭제하시겠습니까?',
            deleteProductVersion : '제품의 버전 정보를 삭제하시겠습니까?',
            recoverProduct : '제품을 복구하시겠습니까?',
        },
        productData : null,
        userId: null,
        errorPage: {
            badRequest : {
                errorCode : 'BAD REQUEST',
                errorMessage : '잘못된 요청입니다.'
            },
            forbidden : {
                errorCode : 'FORBIDDEN',
                errorMessage : '접근 권한이 없습니다.'
            },
            notFound : {
                errorCode : 'NOT FOUND',
                errorMessage : '요청한 페이지를 찾을 수 없습니다.'
            },
            methodNotAllowed : {
                errorCode : 'METHOD NOT ALLOWED',
                errorMessage : '사용할 수 없는 메소드입니다.'
            },
            internalServerError : {
                errorCode : 'INTERNAL SERVER ERROR',
                errorMessage : '서버 내부에 오류가 발생했습니다.'
            },
            // 500
            errorStatus : null,
            // Request failed with status code 500
            errorMessage : null,
            // ERR_BAD_RESPONSE
            errorCode : null,
        },
    },
    getters:{
        isLogin(state){
            return state.token == null ? false : true;
        },
        getToken(state){
            return state.token;
        },
        getUserId(state){
            return state.userId;
        }
    },
    mutations:{
        setToken(state,token){
            state.token = token;
        },
        setUserId(state, userId){
            state.userId = userId;
        },
        setProductData(state,data){
            state.productData = data;
        },
        setProductName(state,data){
            state.productData.productName = data;
        },
        setProductPlatform(state,data){
            state.productData.productPlatform = data;
        }
    },
    plugins: [createPersistedState()]
    
})