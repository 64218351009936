/******************************************************************************
 *
 *  (C) 2022 AhnLab, Inc. All rights reserved.
 *  Any part of this source code can not be copied with any method without
 *  prior written permission from the author or authorized person.
 *
 ******************************************************************************/
<template>
  <div>
    <MainHeader></MainHeader>
    <div class="content-wrapper-vertical-center">
      <div class="content-width-70">
        <router-view></router-view>
      </div>
    </div>
    <MainFooter></MainFooter>
  </div>
</template>
 
<script>
 import MainHeader from './header/MainHeader.vue';
 import MainFooter from './footer/MainFooter.vue';

export default {
    name: 'MainLayout',
    components: {
        MainHeader, 
        MainFooter, 
    },
};
</script>

<style>
.content-wrapper-vertical-center {
  position: relative;
  display:flex; 
  align-items:center
}
</style>