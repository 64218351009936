// ******************************************************************************

//    (C) 2022 AhnLab, Inc. All rights reserved.
//    Any part of this source code can not be copied with any method without
//    prior written permission from the author or authorized person.

// ******************************************************************************
import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../layouts/MainLayout';
import AdminLayout from '../layouts/AdminLayout';
import EmptyLayout from '../layouts/EmptyLayout';
import { store } from '../store/store';
import axios from 'axios';

Vue.use(VueRouter)

const routes = [  
    {
        path: "/osrs/admin/login", 
        component: EmptyLayout,
        children: [
            {
                path: '',
                name: 'AdminLogin',
                component: ()=> import('@/views/Admin/AdminLogin.vue'),
                beforeEnter: function(to, from, next){
                    if(store.getters.isLogin){
                        return next({ name : "AdminMain"});
                    }
                    next();
                }
            },
        ]
    }, 
    {
        path: "/osrs/admin",
        component: AdminLayout,
        beforeEnter: function(to, from, next){
            if(!store.getters.isLogin){
                return next({ path: "/osrs/admin/login" });
            }
            axios.defaults.headers.common['X-AUTH-TOKEN'] = store.getters.getToken;
            next();
        },
        children: [
            {
                path: '',
                name: 'AdminMain',
                component: ()=> import('@/views/Admin/AdminMain.vue')
            },{
                path: 'product',
                name: 'AdminDetail',
                component: ()=> import('@/views/Admin/AdminDetail.vue')
            },
        ]
    },
    {
        path: "/", 
        component: MainLayout,
        children: [
            {
                path: '',
                name: 'UserMain',
                component: ()=> import('@/views/UserMain.vue')
            },
        ],
    },
    {
        path: '*',
        redirect: { path: '/error/404' }
    },
    {
        path: '/error/:status',
        name: 'ErrorPage',
        component: ()=> import('@/components/common/ErrorPage.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  });

export default router;