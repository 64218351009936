/******************************************************************************
 *
 *  (C) 2022 AhnLab, Inc. All rights reserved.
 *  Any part of this source code can not be copied with any method without
 *  prior written permission from the author or authorized person.
 *
 ******************************************************************************/
import Vue from 'vue'
import App from './App.vue'
import router from "./router/routes"
import axios from 'axios'

/* table library : vue-good-table */
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

/* vuetify library */
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
/* vuetify basic icon */
import '@mdi/font/css/materialdesignicons.css'

/* vuex library */
import { store } from './store/store.js'

/* icon library : font awesome */
/* font awesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* font awesome specific icons */
import { faFile, faFileCode } from '@fortawesome/free-regular-svg-icons'
import { faEnvelope, faPlus } from '@fortawesome/free-solid-svg-icons'

Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(VueGoodTablePlugin);
Vue.use(Vuetify);

library.add(faFile, faFileCode, faEnvelope, faPlus)

Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  store,
  router,
  vuetify: new Vuetify(),
  render: h => h(App),
}).$mount('#app')
