/******************************************************************************
 *
 *  (C) 2022 AhnLab, Inc. All rights reserved.
 *  Any part of this source code can not be copied with any method without
 *  prior written permission from the author or authorized person.
 *
 ******************************************************************************/
 <template>
 <div>
    <div class="wm" ref='vantaRef'>
        <v-row>
            <header class="header-main">
                <a href="/">
                    <img alt="Ahnlab logo" src="../../images/img_logo.png" width="80">
                </a>
            </header>
            <div class="banner">
                <div class="banner-text">
                    <h1 style="color:#edeff7">AhnLab Open Source Release System</h1>
                    <p style="color:#b1bbff">AhnLab complies with open source licenses and faithfully fulfills its obligations.</p>
                </div>
            </div>
        </v-row>
    </div>
 </div>
</template>
 
<script>
 import * as THREE from 'three'
 import NET from 'vanta/dist/vanta.waves.min'
 export default {
    mounted() {
        this.vantaEffect = NET({
            el: this.$refs.vantaRef,
            THREE: THREE,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            color: '#283593',
            shininess: 30,
            waveHeight: 15,
            waveSpeed: 1,
            zoom: 1,
        })
    }
 }
</script>
 
<style>
.header-main {
	position:relative;
	top: 0;
	right: 0;
	left: 0;
	z-index: 100;
    background-color: transparent; 
	margin: 30px 0 0 0;
	left:30px;
}
.wm:not(.header-main){
    position: relative;
    display: flex;
    min-height: 30vh;
    width: 100vw;
 }
 .banner {
    position: relative;
    z-index: 1;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 0;
 }
 .banner-text {
    float: center;
    display: inline;
    padding-left: 5%;
    padding-right: 5%;
 }
 </style>