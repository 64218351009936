/******************************************************************************
 *
 *  (C) 2022 AhnLab, Inc. All rights reserved.
 *  Any part of this source code can not be copied with any method without
 *  prior written permission from the author or authorized person.
 *
 ******************************************************************************/
<template>
  <header class="header-admin">
		<nav>
			<a href="/osrs/admin" class="nav-logo">
				<img alt="Ahnlab logo" src="../../images/img_logo.png" width="80">
			</a>
			<ul class="nav-items">
				<li><a href="javascript:;" v-on:click="showDialog()">Account</a></li>
				<v-dialog
					max-width="500"
					v-model="addPasswordModal"
					persistent>
					<EditPasswordModal @close-dialog="closeDialog"></EditPasswordModal>
				</v-dialog>
				<li><a href="javascript:;" v-on:click="logoutBtnClick()">Logout</a></li>
			</ul>
		</nav>
	</header>
</template>

<script>
import EditPasswordModal from '../../components/modal/EditPasswordModal.vue'

export default {
	components: {
		EditPasswordModal,
	},
	data: function() {
		return {
			addPasswordModal: false,
		}
	},
	methods:{
		logoutBtnClick : function(){
			this.$store.commit('setToken',null);
            this.$store.commit('setUserId',null);
			this.$router.go();
		},
		showDialog() {
			if(this.$store.getters.getUserId === null){
				this.$router.go();
			}
			this.addPasswordModal = true;
		},
		closeDialog() {
			this.addPasswordModal = false;
		}
	}

}
</script>

<style>
.header-admin{
	width:100%; 
	position:fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 100;
	height:50px; 
    background-color: #4E8FF8; 
}
.header-admin .nav-logo{
	position:absolute; 
	margin: 15px 0 0 0;
	left:20px;
}
.header-admin .nav-items{
	position:absolute; 
	margin: 0 0 0 0;
	right:20px;
}
.header-admin .nav-items li{
	float:left; 
	padding:10px 20px; 
	list-style:none;
}
.header-admin .nav-items a{
	text-decoration:none; 
	color:#ffffff;
}
</style>