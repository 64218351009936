/******************************************************************************
 *
 *  (C) 2022 AhnLab, Inc. All rights reserved.
 *  Any part of this source code can not be copied with any method without
 *  prior written permission from the author or authorized person.
 *
 ******************************************************************************/
<template>
  <div class="text-center">
    <v-btn
      color="blue accent-1"
      class="mr-4 mb-4"
      :disabled="!this.$store.state.addProductModal.btnValid"
      @click="[postData(), snackbar]"
    >
      {{ btn }}
    </v-btn>

    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      color="#4E8FF8" 
      top
      outlined
      elevation="24"
    >
      {{ msg }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="#333"
          text
          v-bind="attrs"
          @click="closeDialog"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
    props: {
        type : {
            type: String,
            default : 'addProduct'
        },
        submit :{
          type: Boolean,
          default: false,
        }
    },
    data: () => ({
      multiLine: true,
      snackbar: false,
      msg : '확인되었습니다.',
      btn : '확인'
    }),
    created : function() {
        this.msg = eval(`this.$store.state.confirmToastButton.${this.$props.type}.toastMsg`);
        this.btn = eval(`this.$store.state.confirmToastButton.${this.$props.type}.toastBtn`);
    },
    methods: {
        postData () {
            // 상위 컴포넌트에 버튼이 눌러졌다는 의미의 이벤트 전달
            this.$emit("btnClick");
        },
        showDialog() {
          this.msg = eval(`this.$store.state.confirmToastButton.${this.$props.type}.toastMsg`);
          this.snackbar = true;
        },
        closeDialog() {
          this.snackbar = false;
        }
    },
    watch: {
      submit(val) {
        if(val === true) {
          this.showDialog();
        }
      }
    }
}
</script>

<style>
</style>