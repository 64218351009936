/******************************************************************************
 *
 *  (C) 2022 AhnLab, Inc. All rights reserved.
 *  Any part of this source code can not be copied with any method without
 *  prior written permission from the author or authorized person.
 *
 ******************************************************************************/
<template>
  <div>
    <div class="content-wrapper-vertical-center">
      <AdminHeader></AdminHeader>
      <div class="content-width-70">
        <router-view></router-view>
      </div>
    </div>
    <MainFooter></MainFooter>
  </div>
</template>
 
<script>
import AdminHeader from './header/AdminHeader.vue';
import MainFooter from './footer/MainFooter.vue';

export default {
    name: 'AdminLayout',
    components: {
         AdminHeader, MainFooter
    }
};
</script>

<style>
.content-width-70 {
    width: 70%;
    margin: auto;
    padding-bottom: 112px;
}
</style>