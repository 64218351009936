/******************************************************************************
 *
 *  (C) 2022 AhnLab, Inc. All rights reserved.
 *  Any part of this source code can not be copied with any method without
 *  prior written permission from the author or authorized person.
 *
 ******************************************************************************/
<template>
  <div>
    <v-card>
        <v-card-title class="dialog-title">
            <span>
                <v-icon size="30px" color="#82B1FF">mdi-account-outline</v-icon>
                패스워드 변경
                <v-spacer></v-spacer>
            </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="text-center"
            >
                <v-text-field
                    v-model="userPw"
                    label="현재 비밀번호"
                    ref="userPw"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    :rules="[rules]"
                    @click:append="show1 = !show1"
                ></v-text-field>
                <v-text-field
                    v-model="changePw"
                    label="변경할 비밀번호"
                    ref="changePw"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    :rules="[rules]"
                    @click:append="show2 = !show2"
                    @change="recheck"
                ></v-text-field>
                <v-text-field
                    v-model="changePwCheck"
                    label="비밀번호 확인"
                    ref="changePwCheck"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[changePwCheckRule]"
                    :type="show3 ? 'text' : 'password'"
                    @click:append="show3 = !show3"
                ></v-text-field>
                <v-row no-gutters justify="center">
                    <ConfirmToastButton type="changePassword" v-bind:submit=this.submit @btnClick="validate"></ConfirmToastButton>
                    <v-btn
                        class="mr-4 mb-4"
                        @click="closeDialog">
                        취소
                    </v-btn>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ConfirmToastButton from '../../components/common/ConfirmToastButton.vue'

export default {
    components: {
        ConfirmToastButton  
    },
    data: () => ({
        userId: '',
        userPw: '',
        changePw: '',
        changePwCheck: '',
        message: '',
        show1: false,
        show2: false,
        show3: false,
        valid: true,
        submit: false,
        rules: v => !!v || '비밀번호를 입력하세요',
    }),
    created: function(){
        this.userId = this.$store.getters.getUserId;
    },
    methods: {
        changePwCheckRule: function(val) {
            if(!val){
                return "비밀번호를 입력하세요";
            }
            else if(val !== this.changePw){
                return "변경할 비밀번호와 동일하게 입력하세요";
            }
            else {
                return true;
            }
        },
        recheck: function() {
            this.$refs.changePwCheck.validate();
        },
        closeDialog: function() {
            this.reset();
            this.$emit('close-dialog');
        },
        validate() {
            const chkValidate = this.$refs.form.validate();
            if(chkValidate) {
                this.submitForm();
                this.reset();
            }
        },
        reset() {
            this.$refs.form.reset();
            this.submit = false;
            this.message = '';
        },
        submitForm() {
            if(this.changePw !== this.changePwCheck){
                this.recheck();
                return;
            }

            const vm = this;
            var postData = {
                userId : vm.userId,
                userPw : vm.userPw,
                changePw : vm.changePw
            }
            //setting input value
            vm.$axios.patch('/api/admin/password', postData
            ).then(function(response){
                const result = response.data.result; // eslint-disable-line no-unused-vars
                const resultMsg = response.data.message;
                vm.message = resultMsg;
                vm.submit = true;
                // console.log(result, resultMsg);
                setTimeout(() => {
                    vm.$router.go();
                }, 2000);
            }).catch(function(error){
                const resultMsg = error.response.data.message;
                var status = error.response.status;
                vm.message = resultMsg;
                vm.submit = true;
                if(status === 403){
                    vm.$store.commit('setToken',null);
                    vm.$store.commit('setUserId',null);
                    vm.$router.go();
                }
                else{
                    if(resultMsg === "Invalid username or password" || resultMsg === "ID를 입력하세요." || resultMsg === "Password를 입력하세요." || resultMsg === "변경할 Password를 입력하세요." ){
                        setTimeout(() => {
                            vm.$emit('close-dialog');
                        }, 2000);
                    }
                    else{
                        vm.$store.state.errorPage.errorStatus = status
                        vm.$store.state.errorPage.errorMessage = error.message;
                        vm.$store.state.errorPage.errorCode = error.code;
                        vm.$router.push({ name: 'ErrorPage', params: { status : status } });
                    }
                    console.log(error);
                }
                    
            });
        }
    },
    watch:{
        valid(val){
            this.$store.state.addProductModal.btnValid = val;
        },
        message(val){
            this.$store.state.confirmToastButton.changePassword.toastMsg = val;
        }
    }
}
</script>

<style>
.dialog-title {
    color: #82B1FF;
}
</style>