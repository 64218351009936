/******************************************************************************
 *
 *  (C) 2022 AhnLab, Inc. All rights reserved.
 *  Any part of this source code can not be copied with any method without
 *  prior written permission from the author or authorized person.
 *
 ******************************************************************************/
<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  components: {
  }
}
</script>

<style>
body{
  margin:0; 
  padding:0;
}
</style>
